export const THEME_CONFIG = {
    typography: {
        fontFamily: "Manrope, sans- serif"
    },
    palette: {
        orange_theme: {
            primary: {
                main: '#D9A62E',
                light: '#FFE097',
                contrastText: '#FFFFFF',
            },
            secondary: {
                main: '#FFF6E8',
            },
            teritary: {
                main: '#DFA828'
            }
        },
        primary: {
            main: "#D9A62E"
        },
        success: {
            main: "#4caf50"
        },
        cancel: {
            main: "#d32f2f"
        }
    },
}