import {
  Alert,
  Badge,
  Box,
  Button,
  CardMedia,
  CircularProgress,
  Modal,
  Snackbar,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ConfirmationBg from "../../Asset/confirm_bg.png";
import Location from "../../Asset/location.png";
import Bg from "../../Asset/menu_bg.png";
import timeOutGif from "../../Asset/timeout.gif";
import MenuList from "../../components/MenuComponent";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import IconConstants from "../../utils/IconConstants";
import OrderConfirm from "../order/OrderConfirm";

export const Menu = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [cartItems, setCartItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [foodItems, setFoodItems] = useState([]);
  const [customerData, setCustomerData] = useState({});
  const [outletData, setOutletData] = useState({});
  const [toast, setToast] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const [outletModel, setOutletModel] = useState({});
  const [orderButton, setOrderButton] = useState(false);
  const [customerValid, setCustomerValid] = useState("");
  const [menuItems, setMenuItems] = useState([]);
  const [displayItems, setDisplayItems] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);

  const [loader, setLoader] = useState(true);

  const handleToastClose = () => {
    setToast({ ...toast, open: false });
  };

  const getQueryParam = (name) => {
    return new URLSearchParams(location.search).get(name);
  };

  const outlet_key = getQueryParam("o_k");
  const customer_key = getQueryParam("c_k");

  // const outlet_key = getQueryParam("outlet_key");
  // const customer_phone = getQueryParam("id");

  const fetchOutletDetails = async (outlet_key) => {
    try {
      const response = await axios.get(
        `https://gzjc5dhshe.execute-api.ap-south-1.amazonaws.com/prod/api/outlet/getOutletDetails/${outlet_key}`,
        { headers: { Authorization: `${sessionStorage.getItem("token")}` } }
      );
      console.log("RESPONSE =====> ", response?.data?.data[0]);
      setOutletModel(response?.data?.data[0]);
    } catch (error) {
      console.log("ERROR OCCURED WHILE FETCHING THE OUTLET DETAIL");
    }
  };

  const fetchMenuItems = async () => {
    try {
      const response = await axios.get(
        `https://gzjc5dhshe.execute-api.ap-south-1.amazonaws.com/prod/api/menu/getMenuItems`,
        { headers: { Authorization: `${sessionStorage.getItem("token")}` } }
      );
      const menuItems = response?.data?.data?.map((item) => {
        return {
          id: item.menu_id,
          name: item.name,
          price: item.price,
          description: item.description,
          imageUrl: item.image,
          quantity: 0,
        };
      });
      setMenuItems(menuItems);
    } catch (error) {
      console.error("Error fetching outlet stock:", error);
    }
  };

  useEffect(() => {
    settingDisplayItems();
  }, [menuItems, foodItems]);

  const settingDisplayItems = () => {
    console.log("MENU ITEMS ::::::>>>>>>>>>", menuItems);

    try {
      const combinedItems = menuItems.map((item) => {
        if (foodItems.length > 0) {
          console.log("SETTING DISPLAY ITEMS :::", item);
          const matchingStockItem = foodItems.find(
            (stockItem) => stockItem.id === item.id
          );

          console.log("MATCHING ITEM FOUND IS :::::>>>>>>", matchingStockItem);

          return {
            ...item,
            available: matchingStockItem?.is_available
              ? matchingStockItem.is_available
              : false,
          };
        } else {
          return {
            ...item,
            available: false,
          };
        }
      });

      console.log("COMBINED ITEMS ::::>>>>", combinedItems);

      setDisplayItems(combinedItems);
    } catch (error) {
      console.error("ERROR SETTING DISPLAY ITEMS", error);
    }
  };

  console.log("🚀 ~ Menu ~ outlet_key:", outlet_key);
  const fetchOutletStock = async (outletKey) => {
    try {
      const response = await axios.get(
        `https://gzjc5dhshe.execute-api.ap-south-1.amazonaws.com/prod/api/outlet/getoutletstockbykey/${outletKey}`,
        { headers: { Authorization: `${sessionStorage.getItem("token")}` } }
      );
      const foodItems = response.data.data;
      const menuItems = foodItems.map((item) => {
        return {
          id: item.menu_id,
          name: item.name,
          price: item.price,
          description: item.description,
          imageUrl: item.image,
          quantity: 0,
          is_available: item.is_available,
        };
      });
      setFoodItems(menuItems);
      setOutletData({ outlet_id: foodItems[0].outlet_id });
      console.log("🚀 ~ fetchOutletStock ~ response:", response);
    } catch (error) {
      console.log("Error fetching outlet stock:", error);
    }
  };

  const setCustomerID = async (customer_key) => {
    try {
      const response = await axios.post(
        `https://gzjc5dhshe.execute-api.ap-south-1.amazonaws.com/prod/api/customer/getCustomerDetails`,
        { customer_key },
        { headers: { "Content-Type": "application/json" } }
      );
      console.log("🚀 ~ setCustomerID ~ response:", response?.data?.data[0]);

      setCustomerData(response?.data?.data[0]);
      sessionStorage.setItem("token", response?.data?.data[0]?.token);

      await verifyJsonWebToken();
    } catch (error) {
      console.log("Error setCustomerID :", error);
    }
  };

  const verifyJsonWebToken = async () => {
    try {
      const response = await axios.post(
        "https://gzjc5dhshe.execute-api.ap-south-1.amazonaws.com/prod/api/verifyJwt",
        {},
        { headers: { Authorization: `${sessionStorage.getItem("token")}` } }
      );
      console.log(">>>>>>>>>>>>>>>", response);

      setCustomerValid(response?.data?.statusCode === 200 ? true : false);
    } catch (error) {
      console.log("VERIFY TOKEN EXIPRED", error);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (customer_key) {
      setCustomerID(customer_key);
    }
  }, [customer_key]);

  useEffect(() => {
    if (customerValid) {
      fetchOutletDetails(outlet_key);
      fetchOutletStock(outlet_key);
      fetchMenuItems();
      setLoader(false);
    }
  }, [customerValid]);

  const totalQuantity = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );
  const totalPrice = cartItems
    .reduce((total, item) => {
      return total + parseFloat(item.price.replace("₹", "")) * item.quantity;
    }, 0)
    .toFixed(2);

  const handleCartUpdate = (updatedCartItems) => {
    console.log("updated Cart Items", updatedCartItems);

    setCartItems(updatedCartItems);

    let amount = 0;
    updatedCartItems.map((items) => {
      if (items.quantity > 0) {
        amount += items.price * items.quantity;
      }
    });
    setTotalAmount(amount);
  };

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const handleOrderPlacement = async (cartItems) => {
    setOrderButton(true);
    try {
      const ordered_items = cartItems
        .filter((item) => item.quantity > 0)
        .map((item) => {
          return {
            quantity: item.quantity,
            price: item.price,
            menu_id: item.id,
          };
        });

      if (ordered_items.length === 0) {
        return;
      }
      const orderReqObj = {
        customer_id: customerData.customer_id,
        outlet_id: outletData.outlet_id,
        order_items: ordered_items,
      };
      console.log("🚀 ~ handleOrderPlacement ~ orderReqObj:", orderReqObj);

      const response = await axios.post(
        `https://gzjc5dhshe.execute-api.ap-south-1.amazonaws.com/prod/api/order`,
        orderReqObj,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        // setToast({
        //   open: true,
        //   severity: "success",
        //   message: "Order placed successfully!",
        // });
        setConfirmModal(true);
        handleClose();
        setTimeout(() => {
          setConfirmModal(false);
        }, 3000);

        // setTimeout(() => {
        //   // navigate("/order/confirm");
        //   window.location.replace("/order/confirm");
        // }, 100);
      } else {
        setToast({
          open: true,
          severity: "error",
          message: "Failed to place order. Please try again.",
        });
      }
    } catch (error) {
      console.error("handleOrderPlacement error", error);
      setToast({
        open: true,
        severity: "error",
        message: "An error occurred. Please try again.",
      });
    } finally {
      setOrderButton(false);
    }
  };


  console.log("totalQuantity", totalQuantity);


  return (
    <>
      <Box sx={{ position: "relative", height: "100vh" }}>
        {loader ? (
          <Box
            sx={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : customerValid ? (
          <Box
            sx={{
              backgroundImage: `url(${Bg})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              pt: "5%",
              height: "100vh",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                width: 1,
              }}
            >
              <Box sx={{ width: "45%" }}>
                <Box sx={{ width: "41.795vw", height: "7.436vw" }}>
                  <img
                    src={
                      "https://ysquare-order-management.s3.ap-south-1.amazonaws.com/Bucket+Biryani/Logo/bucket_biryani_logopng.png"
                    }
                    style={{ width: "100%", height: "100%" }}
                    alt=""
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  width: "51%",
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "0.5vw",
                  mt: "1%",
                }}
              >
                <Box>
                  <Box sx={{ width: "4.872vw", height: "4.872vw" }}>
                    <img
                      src={Location}
                      style={{ width: "100%", height: "100%" }}
                      alt=""
                    />
                  </Box>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "3.077vw",
                      fontWeight: 600,
                    }}
                  >
                    {outletModel?.address}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box sx={{ margin: "0 auto 0 auto", width: "91.795%" }}>
              <Typography
                // align="center"
                sx={{
                  margin: "0 0 2.57% 0",
                  fontSize: "4.103vw",
                  fontWeight: 800,
                }}
              >
                Our Menu
              </Typography>

              <MenuList
                foodItems={displayItems}
                onCartUpdate={handleCartUpdate}
              />
            </Box>

            <Box sx={{ textAlign: "center", mt: "5%" }}>
              <Button
                disabled={totalQuantity == 0 ? true : false}
                sx={{
                  textTransform: "none",
                  position: "fixed",
                  bottom: "1%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  margin: "0 auto",
                  width: "92%",
                  backgroundColor: "#F7E8EE",
                  borderRadius: "7.28px",
                  height: "12.051vw",
                  boxShadow: "1.95px 1.95px 2.6px 0px #00000026",
                }}
                startIcon={
                  <Badge
                    showZero
                    badgeContent={totalQuantity}
                    color="primary"
                    sx={{
                      "& .MuiBadge-badge": {
                        backgroundColor: "#E99F5B",
                        color: "#FFFFFF",
                        fontSize: "2.308vw",
                        fontWeight: 800,
                        width: "3.226vw",
                        height: "3.226vw",
                        borderRadius: "50%",
                        minHeight: 0,
                        minWidth: 0,
                        border: "1px solid #FFFFFF",
                        p: 0,
                      },
                    }}
                  >
                    <Box sx={{ width: "6.949vw", height: "6.949vw" }}>
                      <img
                        src={IconConstants.Cart}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </Box>
                  </Badge>
                }
                onClick={handleOpen}
              >
                <Typography
                  sx={{
                    fontSize: "4.103vw",
                    fontWeight: 800,
                    color: "#EBA059",
                  }}
                >
                  View Cart{" "}
                  <span>
                    {" "}
                    {totalAmount == 0 ? "" : "(₹ " + totalAmount + ")"}
                  </span>
                </Typography>
              </Button>
            </Box>
          </Box>
        ) : !customerValid ? (
          <Box
            sx={{
              height: "90vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "80%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CardMedia
                component="img"
                sx={{ width: 100, height: 100 }}
                image={timeOutGif}
                alt="Time Out"
              />
              <Typography align="center" fontWeight="bold">
                Session Expired
              </Typography>

              <Button
                href="https://wa.me/918884142400?text=Hi"
                sx={{ mt: "4vh", background: "#28ad64", color: "white" }}
              >
                <Typography
                  sx={{ fontSize: "4vw", mx: "2vw", textTransform: "none" }}
                >
                  Message Us "Hi"
                </Typography>
                <WhatsAppIcon />
              </Button>
            </Box>
          </Box>
        ) : (
          ""
        )}
      </Box>
      <Modal open={openModal} onClose={handleClose}>
        <Box
          sx={{
            maxWidth: "88vw",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: "5%",
            outline: "none",
            border: "none",
            borderRadius: 2,
            backgroundImage: `url(${ConfirmationBg})`,
            backgroundSize: "cover",
          }}
        >
          <Typography sx={{ fontWeight: 700, fontSize: "4.2vw" }}>
            Cart Items
          </Typography>
          {cartItems
            .filter((item) => item.quantity > 0) // Filter out items with 0 quantity
            .sort((a, b) => {
              const priceA = parseFloat(a.price.replace("₹", "")) * a.quantity;
              const priceB = parseFloat(b.price.replace("₹", "")) * b.quantity;
              return priceB - priceA; // Sort from high price to low
            })
            .map(
              (item) =>
                item.quantity > 0 && (
                  <Box key={item.id} sx={{ display: "flex", my: "2%" }}>
                    <Typography sx={{ width: "53vw", fontSize: "3.733vw" }}>
                      {item.name.split("(")?.[0]}
                    </Typography>
                    <Typography
                      sx={{
                        width: "8vw",
                        ml: "2vw",
                        color: "black",
                        fontSize: "3.5vw",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      x {item.quantity}
                    </Typography>
                    <Typography
                      sx={{
                        ml: "1vw",
                        width: "23vw",
                        fontSize: "3.733vw",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        textAlign: "right",
                        fontSize: "3.59vw",
                      }}
                    >
                      ₹{" "}
                      {(
                        parseFloat(item.price.replace("$", "")) * item.quantity
                      ).toFixed(2)}
                    </Typography>
                  </Box>
                )
            )}
          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "space-between",
              fontSize: "3.733vw",
            }}
          >
            <Typography sx={{ fontWeight: 700, fontSize: "4.103vw" }}>
              Total Price:
            </Typography>
            <Typography sx={{ fontWeight: 700, fontSize: "4.103vw" }}>
              ₹ {totalPrice}
            </Typography>
          </Box>
          <Button
            disabled={orderButton}
            variant="contained"
            onClick={() => handleOrderPlacement(cartItems)}
            sx={{
              mt: "2.367vh",
              width: "100%",
              height: "5.908vh",
              background: "#EEEEEE",
              color: "#AA1234",
              fontSize: "4vw",
              fontWeight: 700,
              textTransform: "none",
              borderRadius: "7.28px",
            }}
          >
            Place Order
          </Button>
        </Box>
      </Modal>
      <Snackbar
        open={toast.open}
        autoHideDuration={4000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleToastClose}
          severity={toast.severity}
          sx={{ width: "100%" }}
        >
          {toast.message}
        </Alert>
      </Snackbar>

      <Modal open={confirmModal}>
        <OrderConfirm
          message={<>Thank You!<br />Order Completed</>}
        />
      </Modal>
    </>
  );
};
