import { Navigate, useRoutes } from "react-router-dom";
import { lazy } from "react";
import { Menu } from "../pages/menu/Menu";
import OrderConfirm from "../pages/order/OrderConfirm";
import ManageOutlet from "../pages/outlet/ManageOutlet";
import ManagerMenu from "../pages/menu/ManagerMenu";


function BaseRoutes() {

    let routes = useRoutes([
        {
            path: "/menu",
            children: [
                {
                    path: "outlet",
                    element:
                        <Menu />
                }
            ]
        },
        {
            path: "/order",
            children: [
                {
                    path: "confirm",
                    element:
                        <OrderConfirm />
                }
            ]
        },
        {
            path: "/manager",
            children: [
                {
                    path: "",
                    element: <ManageOutlet />,
                },
                {
                    path: "outlet",
                    element: <ManagerMenu />
                }
            ]
        }
    ])

    return routes;

}

export default BaseRoutes;
