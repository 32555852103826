import logo from './logo.svg';
import './App.css';
import BaseRoutes from './routes/index.route';

function App() {

  let indexRoutes = BaseRoutes()
  return indexRoutes;
}

export default App;
