import { Box, Typography } from "@mui/material";
import IconConstants from "../../utils/IconConstants";
import { useEffect } from "react";

const OrderConfirm = ({ message }) => {

  useEffect(() => {
    setTimeout(() => {
      window.location.href = "https://wa.me/918884142400";
    }, 2400)
  }, [])

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${IconConstants.OrderBg})`,
        backgroundSize: "cover",
      }}
    >

      <Box sx={{ width: "45.867vw", height: "41.067vw" }}>
        <img src={IconConstants.Tick} alt="" style={{ width: "100%", height: "100%" }} />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
          mt: "8%",
          color: "#EBA059",
        }}
      >
        <Typography sx={{ fontSize: "6.667vw", fontWeight: 700 }}>
          {
            message ? message : <></>
          }
        </Typography>
      </Box>
    </Box>
  );
};

export default OrderConfirm;
